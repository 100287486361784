import {
  CardElement,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import stripebadge2 from "../../assets/images/stripe-badge-grey-2.png";

const CARD_ELEMENT_OPTIONS = {
  iconStyle: "solid",
  hidePostalCode: true,
  style: {
    base: {
      iconColor: "rgb(240, 57, 122)",
      color: "rgb(0, 0, 0)",
      fontSize: "14px",
      fontFamily: "inherit",
      fontSmoothing: "antialiased",
      padding: "8px",
      "::placeholder": {
        color: "#CFD7DF",
      },
    },
    invalid: {
      color: "#e5424d",
      ":focus": {
        color: "#303238",
      },
    },
  },
};

export default function CardSection() {
  return (
    <div>
      <div className="input_container">
        <label for="card_number" style={{ fontFamily: "LATO" }}>
          Card Number:
        </label>
        <CardNumberElement options={CARD_ELEMENT_OPTIONS} />
      </div>
      {/* <div style={{ background: "#e1e2e5", textAlign: "center" }}>
        <img
          src={stripebadge2}
          alt="stripe badge cards"
          width={"65%"}
          style={{ borderRadius: "5px" }}
        />
      </div> */}
      <div className="input_container">
        <label for="cvc" style={{ fontFamily: "LATO" }}>
          CVC:
        </label>
        <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
      </div>
      <div className="input_container">
        <label for="expiry_date" style={{ fontFamily: "LATO" }}>
          Expiration Date:
        </label>
        <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
      </div>
    </div>
  );
}
